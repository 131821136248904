import Link from 'next/link';
import Image from 'next/image';
import { useTranslation } from 'next-i18next';
import RoutesEnum from '@/src/enums/routes.enum';
import HoloButton from '@/components/common/Button/HoloButton';
import { DirUtils } from '@/src/util/dir';
import { highlightWords } from '@/src/util/text';
import { cn } from '@/src/util/tailwind';
import useWindowSize from '@/src/hooks/useWindowSize';
import ClientOnlyComponent from '@/components/ClientOnlyComponent';

type MegaHeaderProps = {
  isLandingPage?: boolean;
};
const MegaHeader = (props: MegaHeaderProps) => {
  const { i18n, t } = useTranslation('mega');
  const isArabic = DirUtils.isRtl(i18n.language);
  const { isXSmall, isSmall, isMedium, isLarge } = useWindowSize();
  const isSmallScreen = isXSmall || isSmall || isMedium || isLarge;
  const { isLandingPage } = props;

  return (
    <>
      <div
        dir={i18n.dir()}
        className={cn(
          'relative flex min-h-[calc(100dvh-90px)] items-end justify-start px-6 py-6 text-center  md:min-h-[77vh] md:items-center md:px-14 md:text-start lg:px-[74px]',
        )}
      >
        <div className=" absolute inset-0 z-10 bg-gradient-to-b from-[rgba(102,102,102,0)] to-[rgba(0,0,0,0.9)]"></div>
        {!isArabic ? (
          isSmallScreen ? (
            <Image
              quality={100}
              alt=""
              priority
              layout="fill"
              src="/assets/marketing/landing-sm.png"
              objectFit="cover"
              className="scale-150 transition-transform duration-500 ease-in-out md:scale-100"
            />
          ) : (
            <Image
              quality={100}
              alt=""
              priority
              layout="fill"
              src="/assets/marketing/landing.png"
              objectFit="cover"
            />
          )
        ) : null}

        {isArabic ? (
          isSmallScreen ? (
            <Image
              quality={100}
              alt=""
              priority
              layout="fill"
              src="/assets/marketing/landing-ar-sm.png"
              objectFit="cover"
              className="scale-150 transition-transform duration-500 ease-in-out md:scale-100"
            />
          ) : (
            <Image
              quality={100}
              alt=""
              priority
              layout="fill"
              src="/assets/marketing/landing-ar.png"
              objectFit="cover"
            />
          )
        ) : null}

        <div
          className={cn(
            'relative  z-10 mx-[unset] flex w-full max-w-[943px] flex-col items-center gap-4 md:gap-8 lg:right-10 lg:mx-auto  lg:gap-12 xl:right-32',
            { 'lg:left-10 xl:left-32 xl:right-auto': isArabic },
          )}
        >
          <div className="flex w-full flex-col gap-[27px]">
            <h1 className="max-w-3xl text-3xl font-bold text-white lg:text-[56px] lg:leading-[56px]">
              {t('Heading.Title')}
              &nbsp;
              <div className="inline">
                {isSmallScreen && (
                  <Image
                    priority
                    src="/assets/elance-partner/elance-logo-white.png"
                    alt="elance"
                    width={120}
                    height={36}
                    quality={100}
                    className="hidden md:inline"
                  />
                )}
                {!isSmallScreen && (
                  <Image
                    quality={100}
                    priority
                    src="/assets/elance-partner/elance-logo-white.png"
                    alt="elance"
                    width={168}
                    height={49}
                    className="inline md:hidden"
                  />
                )}
              </div>
            </h1>
            <div className="max-w-[666px]">
              <p className="w-full text-sm font-normal text-white md:text-xl lg:w-[80%]">
                {t('Heading.SubTitleOne')}
              </p>
              <p className="text-sm font-normal text-white md:text-xl">
                {t('Heading.SubTitleTwo')}
              </p>
            </div>
          </div>
          <div className="relative flex w-full flex-row-reverse items-center gap-6 border-t border-t-white/30 pt-6 md:flex-row md:items-start md:justify-between md:gap-0 md:border-none md:pt-0">
            <ClientOnlyComponent>
              <Link href={RoutesEnum.MORTGAGE_PRODUCTS_SERVICES}>
                <HoloButton
                  theme="white"
                  size="lg"
                  text={t('GetStarted', {
                    ns: 'app-common',
                  })}
                  extraClasses={cn(
                    'lg:w-auto lg:mb-0 md:max-w-md  md:max-w-[200px] px-6 md:px-12 max-w-[50%] w-full',
                    DirUtils.getLang(i18n?.language) +
                      `-get-started-${isLandingPage ? 'main' : 'mega'}`,
                  )}
                />
              </Link>
            </ClientOnlyComponent>
            <div
              className={cn(
                'static right-0 flex flex-1 flex-col items-start justify-center md:absolute md:-top-20 md:h-52 md:w-52 md:items-center md:rounded-full md:bg-white lg:-top-28 lg:h-[244px] lg:w-[244px] xl:-top-40',
                { 'left-0 right-auto': isArabic },
              )}
            >
              <h2 className="text-sm font-normal text-white/50 md:text-lg md:font-semibold md:text-black">
                {t('Heading.AdditionalTextOne')}
              </h2>
              <h3 className="text-lg font-bold text-white md:text-base md:font-semibold md:text-black">
                {highlightWords(
                  t('Heading.AdditionalTextTwo'),
                  ['200,000'],
                  'large',
                )}
              </h3>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default MegaHeader;
